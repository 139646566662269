import { Box, FormControl, Text } from '@chakra-ui/react';
import { Form, Formik, FormikHelpers } from 'formik';
import NextLink from 'next/link';
import React from 'react';

import GoogleAuthButton from '~/components/AuthenticationLayout/components/GoogleAuthButton';
import MicrosoftAuthButton from '~/components/AuthenticationLayout/components/MicrosoftAuthButton';
import Button from '~/components/Button';
import EmailInput from '~/components/EmailInput';
import FormLabel from '~/components/FormLabel';
import Input from '~/components/Input';
import Link from '~/components/Link';
import PasswordInput from '~/components/PasswordInput';
import { INVITATON_TYPES } from '~/constants/invitationType';
import { PLATFORMS } from '~/constants/platforms';
import { routes } from '~/constants/routes';
import { SSO_PLATFORMS } from '~/constants/ssoPlatforms';
import { setButtonHeapTrackId } from '~/utils/setButtonHeapTrackId';

import { FormValues } from './LoginScreen.vm';

interface Props {
  platform: PLATFORMS;
  onSubmit: (fields: FormValues, actions: FormikHelpers<FormValues>) => void;
  email?: string;
  heading?: React.ReactNode;
  isInvitation?: boolean;
  googleAuthUrl?: string;
  microsoftAuthUrl?: string;
  invitationType?: INVITATON_TYPES;
}

export function LoginScreenComponent({
  email = '',
  heading,
  isInvitation,
  onSubmit,
  googleAuthUrl,
  microsoftAuthUrl,
  platform = PLATFORMS.foam,
  invitationType,
}: Props) {
  return (
    <>
      {heading}

      <Formik
        initialValues={{ email, password: '' }}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({
          values,
          errors,
          handleSubmit,
          handleBlur,
          handleChange,
          isSubmitting,
        }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <FormControl id="email" marginBottom={4}>
                <FormLabel
                  color="primary.600"
                  fontWeight="medium"
                  fontSize="md"
                >
                  Email address
                </FormLabel>
                {platform == PLATFORMS.foam ? (
                  <EmailInput
                    defaultValue={values.email}
                    isDisabled={isInvitation || isSubmitting}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="address@mail.com"
                    size="lg"
                    width="100%"
                    isRequired
                    autoFocus
                  />
                ) : (
                  <Input
                    defaultValue={values.email}
                    isDisabled={isInvitation || isSubmitting}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="address@mail.com"
                    size="lg"
                    width="100%"
                    isRequired
                    autoFocus
                  />
                )}
              </FormControl>

              <FormControl
                id="password"
                isInvalid={!!errors.password}
                marginBottom={6}
              >
                <FormLabel
                  color="primary.600"
                  fontWeight="medium"
                  fontSize="md"
                >
                  Password
                </FormLabel>
                <PasswordInput
                  value={values.password}
                  isDisabled={isSubmitting}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="Password"
                  size="lg"
                  width="100%"
                  errorTextRequired="Required"
                  isRequired
                  errors={errors.password}
                />

                <Link
                  href={routes[platform].forgotPassword}
                  as={NextLink}
                  color={
                    platform === PLATFORMS.foam ? 'secondary.500' : 'brand.100'
                  }
                  display="inline-block"
                  fontSize="md"
                  fontWeight="medium"
                  marginTop={2}
                  track-id={`sso-${platform}login-forgot-password`}
                >
                  Forgot password?
                </Link>
              </FormControl>

              <Button
                marginBottom={3}
                colorScheme="primary"
                width="100%"
                fontSize="md"
                isLoading={isSubmitting}
                size="lg"
                type="submit"
                track-id={setButtonHeapTrackId(
                  'login',
                  isInvitation,
                  platform,
                  undefined,
                  invitationType
                )}
              >
                Log in
              </Button>
            </Form>
          );
        }}
      </Formik>
      <Box>
        <Text
          color="primary.400"
          fontSize="sm"
          textAlign="center"
          marginBottom={3}
        >
          Or
        </Text>
        <GoogleAuthButton
          googleAuthUrl={googleAuthUrl}
          trackId={setButtonHeapTrackId(
            'login',
            isInvitation,
            platform,
            SSO_PLATFORMS.google,
            invitationType
          )}
        />
        <MicrosoftAuthButton
          microsoftAuthUrl={microsoftAuthUrl}
          trackId={setButtonHeapTrackId(
            'login',
            isInvitation,
            platform,
            SSO_PLATFORMS.microsoft,
            invitationType
          )}
        />
        <Text color="primary.400" fontSize="sm" textAlign="center">
          Don&apos;t have an account?{' '}
          <Link
            as={NextLink}
            href={routes[platform].signup}
            fontWeight="medium"
            color={platform === PLATFORMS.foam ? 'secondary.500' : 'brand.100'}
          >
            Sign up
          </Link>
        </Text>
      </Box>
    </>
  );
}
