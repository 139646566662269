import DocumentTitle from '~/components/DocumentTitle';
import { HeaderLogo } from '~/components/HeaderLogo';
import { withSessionRedirect } from '~/components/withSessionRedirect';
import { PLATFORMS } from '~/constants/platforms';
import { LoginScreen } from '~/screens/LoginScreen';

function Login() {
  return (
    <>
      <DocumentTitle>Login</DocumentTitle>
      <LoginScreen
        heading={<HeaderLogo platform={PLATFORMS.echo} heading="Log in" />}
        platform={PLATFORMS.echo}
      />
    </>
  );
}

export default withSessionRedirect(Login);
