import { PlatformsAuthUrlResponse } from '@orca/api-auth';
import { FormikHelpers } from 'formik';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { useMount } from 'react-use';

import { CONNECT_PLATFORMS_ACTIONS, ORIGIN } from '~/constants';
import { INVITATON_TYPES } from '~/constants/invitationType';
import { PLATFORMS } from '~/constants/platforms';
import { routes } from '~/constants/routes';
import { useAlert } from '~/contexts/alert';
import { getAlertEmailStatus } from '~/contexts/alert/alertFeedBackError';
import { useAuth } from '~/contexts/auth';
import { captureException } from '~/errorsLogging';
import { LoginLayout } from '~/layouts/LoginLayout';
import { PlatformsAuthEvent } from '~/types';
import { AuthTokens } from '~/types/AuthTokens';
import { isAuthServiceSSOLoginError } from '~/utils/isAuthServiceSSOLoginError';
import { isAuthServiceSSOLoginSuccess } from '~/utils/isAuthServiceSSOLoginSuccess';

import { LoginScreenComponent } from './LoginScreen.component';
import { FormValues } from './LoginScreen.vm';

interface LoginScreenProps {
  heading: React.ReactNode;
  platform: PLATFORMS;
  email?: string;
  isRegisteredUser?: boolean;
  invitationType?: INVITATON_TYPES;
}

type Url = string | undefined;

export function LoginScreen({
  email = '',
  isRegisteredUser,
  heading,
  platform = PLATFORMS.foam,
  invitationType,
}: LoginScreenProps) {
  const [googleAuthUrl, setGoogleAuthUrl] = useState<Url>(undefined);
  const [microsoftAuthUrl, setMicrosoftAuthUrl] = useState<Url>(undefined);

  const auth = useAuth();
  const router = useRouter();
  const { setAlertProps } = useAlert();

  useMount(async () => {
    try {
      const AuthPlatformUrl = (await auth.platformsAuthUrl({
        platform: platform === PLATFORMS.foam ? ORIGIN.foam : ORIGIN.echo,
        action: CONNECT_PLATFORMS_ACTIONS.login,
      })) as PlatformsAuthUrlResponse;
      if (AuthPlatformUrl) {
        setGoogleAuthUrl(AuthPlatformUrl.googleUrl);
        setMicrosoftAuthUrl(AuthPlatformUrl.azureUrl);
      }
    } catch (error: any) {
      setGoogleAuthUrl(error);
      setMicrosoftAuthUrl(error);
      const errorProps = {
        errorInfo: { error },
        tags: {
          section: 'loginSSO',
        },
      };
      captureException(error, errorProps);
      setAlertProps({
        isVisible: true,
        title: 'Reload the page',
        status: 'error',
        description: (
          <>
            There was an error obtaining the configuration for Google or
            Microsoft. Please reload the page.
          </>
        ),
      });
    }
  });

  useEffect(() => {
    const handler = (event: MessageEvent) => {
      const platformsAuthEvent: PlatformsAuthEvent = event.data;

      if (isAuthServiceSSOLoginError(platformsAuthEvent)) {
        getAlertEmailStatus(router, platform, setAlertProps);
        const sentryProps = {
          errorInfo: { platformsAuthEvent },
          tags: {
            section: 'loginSSO',
          },
        };
        captureException(new Error(platformsAuthEvent.message), sentryProps);
      }
      if (isAuthServiceSSOLoginSuccess(platformsAuthEvent)) {
        if (isRegisteredUser && email !== platformsAuthEvent.userInfo.email) {
          // If is an invitation account
          setAlertProps({
            isVisible: true,
            title: 'Email doesn’t match invite',
            status: 'error',
            description: (
              <>
                The email provided doesn’t match what we have in our system. If
                you want to use a different email, please reach your contact
                person for a new invitation.
              </>
            ),
          });
        } else {
          auth.loginWithSSOAccount({
            accessToken: platformsAuthEvent.accessToken,
            refreshToken: platformsAuthEvent.refreshToken,
          } as AuthTokens);
        }
      }
    };
    window.addEventListener('message', handler);
    return () => window.removeEventListener('message', handler);
  }, [auth, router, setAlertProps, email, isRegisteredUser, platform]);

  const handleSubmit = async (
    fields: FormValues,
    { setErrors }: FormikHelpers<FormValues>
  ) => {
    setAlertProps({
      isVisible: false,
    });

    try {
      await auth.login({
        email: fields.email.toLowerCase(),
        password: fields.password,
        platform: platform,
      });
    } catch (error: any) {
      if (error.message === '102') {
        getAlertEmailStatus(router, platform, setAlertProps);
      } else if (error.message === '108') {
        router.push({
          pathname: routes.echo.verifyEmail,
          query: { email: fields.email.toLowerCase() },
        });
      } else {
        setErrors({
          password: 'The email and password combination is incorrect',
        });
      }
    }
  };

  return (
    <>
      <LoginLayout platform={platform}>
        <LoginScreenComponent
          heading={heading}
          email={email}
          isInvitation={isRegisteredUser}
          onSubmit={handleSubmit}
          googleAuthUrl={googleAuthUrl}
          microsoftAuthUrl={microsoftAuthUrl}
          platform={platform}
          invitationType={invitationType}
        />
      </LoginLayout>
    </>
  );
}
